<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isSave"
      title="Error"
      subTitle="Start time and end time are required."
      headline-color="black"
      buttonText="OK"
      :showBtnCancel="false"
      :confirm="closeValiationMsg"
      confirm-btn-color="info600Color"
      icon=""
    ></alert-confirm>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px">
          <h3 class="pageHeadline" t-data="page-headline">
            Doctor Schedule
          </h3>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title
        :class="{'mb-0': true, 'pb-0': hasDepartments}"
        t-data="card-doctor-detail"
      >
        <v-row>
          <v-col cols="6" sm="6" class="mt-0 pt-0 pb-0">
            <p class="edit-field-label sub-body-bold" t-data="info-label">
              Doctor Name
            </p>
            <v-text-field
              class="can-disable-selector"
              outlined
              dense
              :disabled="true"
              :value="doctorName"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="mt-0 pt-0 pb-0">
            <p
              v-if="departmentItems"
              class="edit-field-label sub-body-bold"
              t-data="info-label"
            >
              Department<span class="danger500Color--text">*</span>
            </p>
            <v-select
              outlined
              dense
              class="can-disable-selector mb-4"
              placeholder="Select Department"
              v-model="scheduleObject.department_id"
              :menu-props="{bottom: true, offsetY: true}"
              :items="departmentItems"
              @change="changeDepartment"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-form v-if="isSelectedDepartment" ref="form" lazy-validation>
      <v-card class="mt-5" v-for="(dayOfWeek, i) in schedules" :key="i">
        <template v-if="i == 0">
          <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
            <h6 class="mb-0 card-title" t-data="info-title">
              Schedule Information
            </h6>
            <v-spacer></v-spacer>
            <p
              class="edit-field-label sub-body-bold mr-4"
              t-data="schedule-status-title"
            >
              Schedule
            </p>
            <v-switch
              color="primary200Color"
              v-model="scheduleObject.schedule_status"
              :disabled="!hasPermEdit()"
              :false-value="1"
              :true-value="0"
              class="mb-0 pb-0 custom-switch"
            ></v-switch>
            <p
              class="edit-field-label sub-body-bold ml-4 mr-4"
              t-data="appointment-status-title"
            >
              Appointment
            </p>
            <v-switch
              color="primary200Color"
              v-model="scheduleObject.appointment_status"
              :disabled="!hasPermEdit()"
              :false-value="1"
              :true-value="0"
              class="mb-0 pb-0 custom-switch"
            ></v-switch>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-0">
            <v-row align="start" justify="start" class="mt-0">
              <v-col cols="12" class="py-0">
                <p
                  class="body-regular warning-content mb-0"
                  t-data="warning-content"
                >
                  Please save the data before changing the Department, otherwise
                  the data will not be saved in the system.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            {{ getDaysOfWeek(i) }}
          </h6>
        </v-card-title>
        <v-card-text class="mt-0">
          <TimeSlot
            v-for="(schedule, index) in dayOfWeek"
            :hasPermEdit="hasPermEdit()"
            :key="index"
            :timeslotObj="schedule"
            :removeSchedule="() => removeSchedule(i, index)"
            :addNewSchedule="() => addNewSchedule(i)"
          ></TimeSlot>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <v-spacer></v-spacer>
      <edit-buttons
        v-if="isSelectedDepartment"
        class="mt-3"
        :show-remove="false"
        :show-preview="false"
        :show-save="hasPermEdit()"
        :on-cancel="showCancelPopup"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {mapState, mapActions} from 'vuex'
import AlertConfirm from '@/components/AlertConfirm'
import TimeSlot from '@/components/TimeSlot'
import {
  hasPermissionsByOrgs,
  snackbarFailContent,
  snackbarSuccessContent
} from '@/helper/helper'
import EditButtons from '@/components/EditButtons'
import SnackBar from '@/components/SnackBar'
import PopupLoading from '@/components/PopupLoading'

export default {
  components: {
    EditButtons,
    AlertConfirm,
    TimeSlot,
    SnackBar,
    'v-popup-loading': PopupLoading
  },
  name: 'Schedules',
  data() {
    return {
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'doctors',
          disabled: false,
          href: '/doctor-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      isError: false,
      isCancel: false,
      isCreateSuccess: false,
      isSave: false,
      isCreateFail: false,
      snackTitle: 'added doctor schedules',
      pageName: ''
    }
  },
  computed: {
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    snackbarSuccess() {
      return snackbarSuccessContent('doctor', this.snackTitle)
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Doctors', links: true, to: '/doctors'}
      ]
    },
    departmentItems() {
      const items = []
      for (let department of this.doctorObject.department_list) {
        items.push({
          text: department.name,
          value: department.id
        })
      }
      return items
    },
    isSelectedDepartment() {
      return this.scheduleObject.department_id !== ''
    },
    hasDepartments() {
      return this.doctorObject.department_list.length > 0
    },
    doctorName() {
      const nameByLang = []
      if (
        this.doctorObject.contents.length > 0 &&
        this.doctorObject.contents[0].name &&
        this.doctorObject.contents[1].name
      ) {
        if (this.doctorObject.contents[0].code === 'th')
          nameByLang.push(
            `${this.doctorObject.contents[0].prefix_name}${this.doctorObject.contents[0].name}`
          )
        else {
          nameByLang.push(
            `${this.doctorObject.contents[1].prefix_name}${this.doctorObject.contents[1].name}`
          )
        }
        if (this.doctorObject.contents[0].code === 'en')
          nameByLang.push(
            `${this.doctorObject.contents[0].prefix_name}${this.doctorObject.contents[0].name}`
          )
        else {
          nameByLang.push(
            `${this.doctorObject.contents[1].prefix_name}${this.doctorObject.contents[1].name}`
          )
        }
      }
      return nameByLang.length > 0 ? nameByLang.join(' / ') : ''
    },
    ...mapState('doctor', [
      'doctorObject',
      'isLoading',
      'scheduleObject',
      'schedules'
    ])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  async created() {},
  async mounted() {
    await this.fetchDetails()
  },
  watch: {},
  methods: {
    async changeDepartment(departmentId) {
      await this.setSchedules({
        id: this.$route.params.id,
        departmentId: departmentId
      })
    },
    getDaysOfWeek(i) {
      const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
      return days[i] || 'Invalid day' // Handles invalid indices
    },
    removeSchedule(dayOfWeek, index) {
      this.schedules[dayOfWeek].splice(index, 1)
    },
    addNewSchedule(i) {
      this.schedules[i].map(obj => (obj.is_final = false))
      this.schedules[i].push({
        id: i + 1 + '_' + (this.schedules[i].length + 1),
        start_time: '',
        end_time: '',
        remark: '',
        tooltip: '',
        is_final: true
      })
    },
    async fetchDetails() {
      await this.setDoctorId(this.$route.params.id)
      const isViewOnly = !hasPermissionsByOrgs('CanEditDoctor', [
        this.doctorObject.org_codename
      ])
      await this.setOrgInfo(isViewOnly)
    },
    hasPermEdit() {
      return hasPermissionsByOrgs('CanEditDoctor', [
        this.doctorObject.org_codename
      ])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    closeValiationMsg() {
      this.isSave = false
    },
    onCancel() {
      this.$router.push({
        name: 'doctor-list'
      })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    showSuccess() {
      this.$router.replace({})
      this.isCreateSuccess = true
      this.snackTitle = 'added schedules'
    },
    async onSave() {
      const formatedSchedules = this.schedules
        .map((days, index) =>
          days
            .map(time => {
              time.day_of_week = index + 1
              time.id = isNaN(time.id) ? 0 : time.id
              return time
            })
            .filter(time => time.start_time || time.end_time)
        )
        .flat()

      if (
        formatedSchedules.filter(
          time =>
            (time.start_time && !time.end_time) ||
            (!time.start_time && time.end_time)
        ).length > 0
      ) {
        this.isSave = true
      } else {
        if (
          formatedSchedules.filter(time => time.start_time >= time.end_time)
            .length == 0
        ) {
          const status = await this.addSchedules({
            id: this.$route.params.id,
            pkg: {
              department_id: this.scheduleObject.department_id,
              schedule_status: this.scheduleObject.schedule_status,
              appointment_status: this.scheduleObject.appointment_status,
              schedules: formatedSchedules.map(obj => {
                delete obj.is_final
                return obj
              })
            }
          })
          if (status) {
            await this.setSchedules({
              id: this.$route.params.id,
              departmentId: this.scheduleObject.department_id
            })
            this.scrollToTop()
            this.showSuccess()
          } else {
            this.isCreateFail = true
          }
        }
      }
    },
    ...mapActions('doctor', [
      'addSchedules',
      'resetState',
      'setDoctorId',
      'setOrgInfo',
      'setSchedules'
    ])
  }
}
</script>

<style scoped>
.symptomatic-box-error {
  color: #ff5252 !important;
}
.v-input--is-readonly {
  background: #ecf0f3 !important;
}
</style>
